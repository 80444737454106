<style scoped>
form {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 7px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  padding: 2rem;
  background-color: white;
  margin: 0rem 3rem;
}
.v-icon.v-icon {
  color: #707070;
}
h1 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.342);
  margin-top: 1rem;
  font-weight: 700;
}
div.col.col-12.wrapper {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.wrapper:has(:not(.v-chip)) i.v-icon.notranslate.mdi.mdi-information {
  height: 3.5rem;
}
.wrapper:has(.v-chip) i.v-icon.notranslate.mdi.mdi-information {
  height: 4.5rem;
}
button[type="submit"] {
  width: 100%;
  border: solid 1.5px #3d260f !important;
  min-width: 13rem !important;
}
.row + .row {
  margin-top: 0rem;
}
</style>
<style>
form.v-form
  > div:first-child:has(label.v-label--active)
  .v-input__slot
  fieldset
  legend {
  width: 131.25px !important;
}
form.v-form
  > div:nth-child(2):has(label.v-label--active)
  .v-input__slot
  fieldset
  legend {
  width: 175px !important;
}
form.v-form
  > div:nth-child(3)
  div:first-child:has(label.v-label--active)
  .v-input__slot
  fieldset
  legend {
  width: 140px !important;
}
form.v-form
  > div:nth-child(3)
  div:last-child:has(label.v-label--active)
  .v-input__slot
  fieldset
  legend {
  width: 137.5px !important;
}
form.v-form
  > div:nth-child(4):has(label.v-label--active)
  .v-input__slot
  fieldset
  legend {
  width: 138.5px !important;
}
div.v-input.file-upload.error--text div.v-input__slot fieldset,
div.v-input.file-upload.v-input--is-label-active div.v-input__slot fieldset {
  border-style: solid;
  border-radius: 0px !important;
}
div.v-input.file-upload div.v-input__slot fieldset {
  border-style: dashed;
  border-radius: 0px !important;
}
div.v-input.file-upload.v-input--is-label-active div.v-input__slot fieldset {
  background: rgba(59, 59, 59, 0.06);
}
div.v-input.file-upload.theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background-color: white;
}
@media screen and (max-width: 880px) {
  form.v-form {
    margin: 0rem 1rem !important;
  }
  .third-row-container {
    display: flex;
    flex-direction: column;
  }
  .third-row-container div.col.col-6 {
    max-width: 100% !important;
  }
  .third-row-container div.col.col-6:first-child {
    padding-bottom: 0px;
  }
  h1 {
    font-size: 1.8rem;
  }
}
</style>
<template>
  <v-container>
    <!-- <v-row class="text-center"> -->
    <!-- <v-col cols="12" id="formContainer"> -->
    <!-- <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
          v-if="!iFrameLoaded"
        ></v-progress-circular>
        <transition name="slide-y-transition">
          <iframe
            id="UploadFormEmbed"
            title="Create New Composition"
            v-show="iFrameLoaded"
            @load="iFrameLoaded = true"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            :src="
              '/upload_form.html?FormID=' +
              uploadFormID +
              '&MyScoreToken=' +
              myScoreToken +
              '&UploadToken=' +
              uploadToken +
              '&RedirectOnSubmit=' +
              encodeURIComponent(getRedirectOnSubmit())
            "
            frameborder="0"
            style="min-width: 100%; border: none"
            scrolling="no"
            v-iframeResize="{ log: false }"
          >
          </iframe>
        </transition> -->
    <!-- </v-col> -->
    <!-- </v-row> -->
    <!-- Create an upload form titled "Upload New Composition" -->
    <v-row class="text-center mb-4">
      <v-col cols="12">
        <h1>Upload New Composition</h1>
      </v-col>
    </v-row>
    <v-row class="text-center mb-4" v-if="error">
      <v-col cols="12">
        <v-alert
          text
          elevation="1"
          outlined
          prominent
          type="error"
          icon="mdi-cloud-alert"
        >
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    <v-form @submit.prevent="submit" ref="uploadForm" lazy-validation>
      <v-col cols="12" v-cloak @drop.prevent="dropScoreFile" @dragover.prevent>
        <v-row class="text-center">
          <v-file-input
            class="file-upload"
            v-model="scoreFile"
            :rules="scoreFileValidation"
            label="Score Upload (PDF)"
            color="secondary"
            placeholder="Score Upload (PDF)"
            prepend-icon=""
            append-icon="mdi-file-pdf-box"
            outlined
            filled
            required
            :disabled="uploadingFiles"
            flat
            accept="application/pdf"
          >
            <template v-slot:selection="{ text }">
              <v-chip label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input></v-row
        >
      </v-col>
      <v-row class="text-center">
        <v-col
          cols="12"
          v-cloak
          @drop.prevent="handleMusicFiles($event.dataTransfer.files)"
          @dragover.prevent
        >
          <v-file-input
            class="file-upload"
            :value="musicFiles"
            counter
            :rules="musicFilesValidation"
            label="Sheet Music Upload (PDFs)"
            color="secondary"
            multiple
            placeholder="Sheet Music Upload (PDFs)"
            prepend-icon=""
            append-icon="mdi-file-pdf-box"
            outlined
            :show-size="1000"
            filled
            :hint="'Optional'"
            :persistent-hint="true"
            :disabled="uploadingFiles"
            flat
            accept="application/pdf"
            @change="handleMusicFiles"
            @click:clear="musicFiles = []"
          >
            <template v-slot:selection="{ text, index }">
              <v-chip
                label
                color="primary"
                close
                close-icon="mdi-close-box"
                @click:close="deleteMusicFileItem(musicFiles[index])"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <v-row class="text-center third-row-container">
        <v-col cols="6" v-cloak @drop.prevent="dropCoverFile" @dragover.prevent>
          <v-file-input
            class="file-upload"
            v-model="coverPage"
            counter
            :rules="coverPageValidation"
            label="Web Thumbnail (JPG)"
            color="secondary"
            placeholder="Web Thumbnail (JPG)"
            prepend-icon=""
            append-icon="mdi-image-plus"
            outlined
            :show-size="1000"
            filled
            :disabled="uploadingFiles"
            flat
            :hint="'Optional'"
            :persistent-hint="true"
            accept="image/jpeg"
          >
            <template v-slot:selection="{ text }">
              <v-chip label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="6" v-cloak @drop.prevent="dropAudioFile" @dragover.prevent>
          <v-file-input
            class="file-upload"
            v-model="previewAudio"
            counter
            :rules="previewAudioValidation"
            label="Preview Audio (MP3)"
            color="secondary"
            placeholder="Preview Audio (MP3)"
            prepend-icon=""
            append-icon="mdi-music"
            outlined
            :show-size="1000"
            filled
            :disabled="uploadingFiles"
            flat
            :hint="'Optional'"
            :persistent-hint="true"
            accept="audio/mpeg"
          >
            <template v-slot:selection="{ text }">
              <v-chip label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="wrapper"
          cols="12"
          v-cloak
          @drop.prevent="dropWrapperFile"
          @dragover.prevent
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-information</v-icon></template
            >
            <span
              >A wrapper is a protective cardstock cover or jacket that wraps
              arround the Score and Parts.</span
            >
          </v-tooltip>
          <v-file-input
            class="file-upload"
            v-model="wrapperFile"
            counter
            :rules="wrapperFileValidation"
            label="Wrapper/Folio (PDF)"
            color="secondary"
            placeholder="Wrapper/Folio (PDF)"
            prepend-icon=""
            append-icon="mdi-file-pdf-box"
            outlined
            :show-size="1000"
            filled
            :disabled="uploadingFiles"
            flat
            :hint="'Optional'"
            :persistent-hint="true"
            accept="application/pdf"
          >
            <template v-slot:selection="{ text }">
              <v-chip label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="4" style="max-width: max-content">
          <v-btn
            type="submit"
            :x-large="true"
            color="success"
            :loading="uploadingFiles"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { v4 } from "uuid";
import path from "path";

const MYSCORE_BACKEND_ROOT = process.env.VUE_APP_MYSCORE_BACKEND;
const DEFAULT_UPLOAD_ERROR_MESSAGE =
  "There was a problem creating your upload. Please try again later and contact support if this continues.";

import { AuthenticatedFetch as composerFetch } from "../helpers/Requests";
import CloudFileUtils from "../helpers/CloudFileUtils";

export default {
  name: "Uploader",
  data: function () {
    return {
      // iFrameLoaded: false,
      myScoreToken: localStorage.getItem("myscore-service-composer-auth"),
      uploadToken: v4(),
      scoreFile: null,
      musicFiles: [],
      coverPage: null,
      error: null,
      previewAudio: null,
      wrapperFile: null,
      uploadingFiles: false,
      scoreFileValidation: [
        function (file) {
          if (!file) return "Must have one file.";
          if (file.type !== "application/pdf") {
            return "Must be a PDF file.";
          }
          return true;
        },
      ],
      musicFilesValidation: [
        function (files) {
          // if (files.length === 0) {
          //   return "Must have at least one file.";
          // }

          for (let i = 0; i < files.length; i++) {
            if (files[i].type !== "application/pdf") {
              return "Must be PDF files.";
            }
          }
          return true;
        },
      ],
      coverPageValidation: [
        function (file) {
          // Cover page not required
          if (!file) return true;

          if (file.type !== "image/jpeg") {
            return "Must be JPG file.";
          }
          return true;
        },
      ],
      previewAudioValidation: [
        function (file) {
          // audio not required
          if (!file) return true;

          if (file.type !== "audio/mpeg") {
            return "Must be MP3 file.";
          }
          return true;
        },
      ],
      wrapperFileValidation: [
        function (file) {
          // wrapper not required
          if (!file) return true;

          if (!file || file.type !== "application/pdf") {
            return "Must be PDF file.";
          }
          return true;
        },
      ],
      // uploadFormID: process.env.VUE_APP_MYSCORE_UPLOAD_FORM_ID,
    };
  },
  methods: {
    deleteMusicFileItem: function (selected) {
      const index = this.musicFiles.indexOf(selected);
      if (index !== -1) {
        this.musicFiles.splice(index, 1);
      }
    },
    dropScoreFile: function (e) {
      this.scoreFile = e.dataTransfer.files[0];
    },
    handleMusicFiles: function (files) {
      const newFiles = Array.from(files);
      const uniqueFiles = this.removeDuplicates([
        ...this.musicFiles,
        ...newFiles,
      ]);
      this.musicFiles = uniqueFiles;
    },
    removeDuplicates: function (files) {
      const uniqueSet = new Set(files.map((file) => file.name));
      return Array.from(uniqueSet, (fileName) =>
        files.find((file) => file.name === fileName)
      );
    },
    dropCoverFile: function (e) {
      this.coverPage = e.dataTransfer.files[0];
    },
    dropAudioFile: function (e) {
      this.previewAudio = e.dataTransfer.files[0];
    },
    dropWrapperFile: function (e) {
      this.wrapperFile = e.dataTransfer.files[0];
    },
    submit: async function () {
      this.uploadToken = v4();

      const url = new URL(MYSCORE_BACKEND_ROOT);
      this.error = null;

      if (this.$refs.uploadForm.validate()) {
        this.uploadingFiles = true;

        // Create the upload job via a PUT request to /upload
        url.pathname = "/api/v1/upload";
        const res = await composerFetch(url.toString(), {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: this.uploadToken }),
        });

        const { upload: uploadRes } = await res.json();
        if (uploadRes.status != "CREATED") {
          this.uploadingFiles = false;
          this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
          return;
        }

        // Upload each file via a PUT request to /upload/:token/file/:filename
        for (let i = 0; i < this.musicFiles.length; i++) {
          const file = this.musicFiles[i];
          let fileWithoutExt = file.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();
          url.pathname = `/api/v1/upload/${this.uploadToken}/file/${encodeURI(
            fileWithoutExt
          )}`;

          try {
            await CloudFileUtils.upload(url.href, file);
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // Score upload
        if (this.scoreFile) {
          let fileWithoutExt = this.scoreFile.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();
          if (!fileWithoutExt.toLowerCase().includes("score")) {
            fileWithoutExt += " Score";
          }
          url.pathname = `/api/v1/upload/${this.uploadToken}/file/${encodeURI(
            fileWithoutExt
          )}`;
          try {
            await CloudFileUtils.upload(url.href, this.scoreFile);
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // If there is a cover page, upload it
        if (this.coverPage) {
          let fileWithoutExt = this.coverPage.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();

          url.pathname = `/api/v1/upload/${
            this.uploadToken
          }/metaFile/${encodeURI(fileWithoutExt)}`;

          try {
            await CloudFileUtils.upload(url.href, this.coverPage);
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // If there is a preview audio, upload it
        if (this.previewAudio) {
          let fileWithoutExt = this.previewAudio.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();
          url.pathname = `/api/v1/upload/${
            this.uploadToken
          }/metaFile/${encodeURI(fileWithoutExt)}`;

          try {
            await CloudFileUtils.upload(url.href, this.previewAudio);
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // If there is a wrapper file, upload it
        if (this.wrapperFile) {
          let fileWithoutExt = this.wrapperFile.name
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9_ -]/g, " ")
            .trim();
          url.pathname = `/api/v1/upload/${
            this.uploadToken
          }/metaFile/${encodeURI(fileWithoutExt)}`;

          try {
            await CloudFileUtils.upload(url.href, this.wrapperFile);
          } catch (err) {
            this.uploadingFiles = false;
            this.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
            return;
          }
        }

        // Call the /upload/:token/start endpoint to start processing the upload
        url.pathname = `/api/v1/upload/${this.uploadToken}/start`;
        await composerFetch(url.toString(), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        // Redirect to the upload page
        window.location.href = this.getRedirectOnSubmit();
      }
    },
    getRedirectOnSubmit: function () {
      let origin = window.location.origin;
      let completePath = "/";

      if (origin.startsWith("https://marcus-devops")) {
        completePath = window.location.pathname;
      }

      if (
        origin.startsWith("http://localhost") ||
        origin.startsWith("https://marcus-devops")
      ) {
        completePath = path.join(completePath, "/#");
      }

      return origin + path.join(completePath, "/upload/" + this.uploadToken);
    },
  },
};
</script>
