export default class CloudFileUtils {
  static upload(url: string, file: any): Promise<void> {
    console.log("Sending to url: " + url);
    return new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();
        const token = localStorage.getItem("myscore-service-composer-auth");
        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            if (this.status != 201) {
              reject();
            } else {
              resolve();
            }
          }
        });
        xhr.open("PUT", url);
        xhr.onerror = function () {
          reject();
        };
        xhr.setRequestHeader("Content-Type", file.type);
        if (token) {
          xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        }
        xhr.send(file);
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }
}
