export async function AuthenticatedFetch(
  url: string,
  options?: any
): Promise<Response> {
  if (!options) {
    options = {};
  }

  if (!options.headers) {
    options.headers = {};
  }

  const token = localStorage.getItem("myscore-service-composer-auth");
  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const res = await fetch(url, options);

  if (res.status === 403 || res.status === 401) {
    window.location.href =
      process.env.VUE_APP_MYSCORE_LOGIN_PAGE ||
      "https://www.jwpepper.com/sheet-music/my_score/ms-login.jsp";
  }

  return res;
}
