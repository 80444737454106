<template lang="pug">
  v-container(:style="'height: ' + (type === 'slim' ? '100px' : '400px')")
    v-row.fill-height(align-content="center" justify="center")
      v-col.text-subtitle-1.text-center(cols="12")
        | {{ title }}
      v-col(cols="6")
        v-progress-linear(
          color="primary"
          indeterminate
          rounded
          height="8"
        )
</template>

<script>
export default {
  name: "Progress",
  props: {
    title: {
      type: String,
      default: "Loading",
    },
    type: {
      type: String,
      default: "normal",
    },
  },
};
</script>
