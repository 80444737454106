import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#35647b", // My Score Blue
        secondary: "#1e1e1e", // JWP dark
        accent: "#8d612b", // JWP Orange
        error: "#9d1e1e",
        info: "#2196F3",
        success: "#BF8C4E", // My Score "Call to Action" Orange
        warning: "#FFC107",
      },
    },
  },
});
