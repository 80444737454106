import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import ManageUpload from "@/views/ManageUpload.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/upload/:uploadToken",
      name: "ManageUpload",
      component: ManageUpload,
      props: true,
    },
    {
      path: "/upload/:uploadToken/:step",
      name: "ManageUploadStep",
      component: ManageUpload,
      props: true,
    },
  ],
});
